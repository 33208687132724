<template>
    <div class="d-flex flex-column position-fixed top-0 start-0 h-100 w-100 bg-canvas">
        <HeaderMain></HeaderMain>





        <section class="flex-fill d-flex flex-column container-fluid px-0 ">
            <div class="row g-0 mx-0 h-100">
                <div class="col-12 d-flex --align-items-center d-flex flex-row h-100">

					<!-- sidebar -->
                    <div class="sidebar bg-white --p-2 pt-4 shadow-sm --position-fixed d-flex flex-column"
						:style="{
							'z-index': 10, 
							'min-width': '320px'
						}"
						>
						<div class="px-2">
							<h3 class="fs-4 fw-normal mb-0 pb-1 ps-3">Dashboard</h3>
							<small class="d-block ps-3 pe-2 mb-3 text-muted">"{{ current.app?.title }}" Application</small>
						</div>
						
						<div v-if="!loading" class="flex-fill px-2">
							<ListGroup v-if="workbooks?.length" class="align-to-left" :collection="workbooks" :onClick="openWorkbook" :onOption="formOnOption"></ListGroup>
							<div class="mx-3 px-3 alert alert-warning" v-else>No Workbooks were found</div>
						</div>

						<div v-else class="flex-fill px-2">
						</div>

						<FooterMain class=""></FooterMain>
                    </div>

                    <div class="content bg-canvas d-flex flex-column flex-fill">
                        <div class="bg-white border-bottom border-start d-flex flex-row navbar-wrapper shadow-sm">
                            <div class="ps-3 py-2">
								<ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

								<nav class="d-inline-block position-absolute ms-3" style="--bs-breadcrumb-divider: '›';">
									<ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
										<li class="breadcrumb-item">
										<a class="text-decoration-none" href="#"
											@click.prevent="$root.goto( $root.links.APPS )"
										>Apps</a>
										</li>
										<li class="breadcrumb-item">
											<!-- <a class="text-decoration-none" href="#"
											@click.prevent="$root.goto( $root.links.APPS + '/' + current.app.name )"
											></a> -->
											{{ current.app?.title }}
										</li>
										<!-- <li v-if="current.workbook" class="breadcrumb-item">{{ current.workbook?.title }}</li>
										<li v-if="current.form" class="breadcrumb-item">{{ current.form?.title }}</li> -->
									</ol>
								</nav>
                            </div>

							<span class="flex-fill"></span>

							<span class="py-2 pe-4">
								<!-- <ButtonCircle class="" icon="analytics"></ButtonCircle> -->
								<ButtonCircle class="ms-2" icon="share"
									:onClick="shareApp"></ButtonCircle>
								<ButtonMain class="ms-2 pe-3 rounded-pill btn-sm fs-6" icon="edit" 
									:onClick="editApp">Edit App</ButtonMain>
							</span>
						</div>

						<!-- content -->
                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <div class="form-wrapper mx-auto bg-white rounded-5 p-5 shadow-sm d-flex flex-row">
								<PlaceholderBox></PlaceholderBox>

								<span class="px-4">
									<h2 class="fw-light">{{ current.app?.title }}</h2>
									<p class="text-muted lead">{{ current.app?.excerpt }}</p>
								</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>









        <!-- <div class="container-fluid overflow-scroll flex-fill w-100 pt-5">
            <div class="">
                <div class="mx-auto" :style="{width: variableContainerWidth + 'px', 'min-height': '60vh'}">
                    <div class="" v-for="(item, index) in workbooks" :key="index" >
                        <WorkbookCard class="mb-4 pt-5 float-start mx-3 "
							:showIcon="false"
                            :data="item"
                            :onClick="openWorkbook"
                            :onOption="formOnOption"
                            :width="current.content.cardWidth"
                            :height="current.content.cardHeight"
                            ></WorkbookCard> 
                    </div>

                    <div v-if="workbooks?.length === 0 && mounted" class="col-12 text-center">
                        <h1>Create your first Workbook</h1>
                        <a class="btn btn-outline-primary py-0 me-2" href="#" @click.prevent="createApp()">Create</a>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>

// import WorkbookCard from "./widgets/WorkbookCard.vue";

export default {
    name: 'AppRun',

    components: {
        // WorkbookCard
    },

    data() {
        return {
			loading: false,

            current: {
				app: null,
				workbook: null,
				workbooks: null,
				content: {
					width: document.body.offsetWidth,
					cardWidth: 200,
					cardHeight: 200
				}
                // form: null,
                // entry: null,
                // fileds: null
            }, 
			// formPanel: null,
			// offcanvas: null
        }
    },

	computed: {
		workbooks() {
			let workbooks = [];

			if(this.current?.workbooks) {
				(this.current?.workbooks?.length > 0) && this.current.workbooks.map( (item) => {
					let workbook = {...{ icon: 'article'}, ...JSON.parse(JSON.stringify(item))};
					workbooks.push(workbook);
				})
			}
			return workbooks;
		}, 

        variableContainerWidth() {
            let cardWidth = this.current.content.cardWidth + 32;
            let modules = Math.floor(this.current.content.width / cardWidth);
            let width = modules > this.current?.workbooks?.length? this.current?.workbooks?.length * cardWidth : modules * cardWidth;
			console.log('variableContainerWidth', modules, this.current?.workbooks?.length, width)
            return width
        }

		// options() {
		// 	let clean = { ...this.$ui.helper.options.GENERIC }; clean.title = 'Clean Data'; clean.disabled = true;
		// 	let embed = { ...this.$ui.helper.options.GENERIC }; embed.title = 'Embed Code'; embed.disabled = true;


		// 	return [
        //         this.$ui.helper.options.DETAILS,
        //         this.$ui.helper.options.DUPLICATE,
        //         this.$ui.helper.options.DELETE,
        //         clean,
		// 		embed,
        //     ]
		// }, 

		// forms() {
		// 	return this.$store.state?.forms || [];
		// },

		// entries() {
		// 	return this.$store.state?.entries;
		// },

        // entry() {
        //     let data = JSON.parse(JSON.stringify(this.current.entry));
        //     return data;
        // },

        // fields() {
		// 	let data;
		// 	if(this.current?.fields) {
		// 		data = this.current?.fields;
		// 	}
        //     return data;
        // }

	},



	watch: {
		// '$route' (to) {
		// 	if(this.$route.params?.workbook && to?.name == "WorkbookDetails") {
		// 		this.setCurrentForm();
		// 	}
		// }
	},


    methods: {

        // panel(action) {
		// 	if(!this.offcanvas) {
		// 		let panel = document.getElementById('form-panel');
		// 		this.offcanvas = new window.bootstrap.Offcanvas(panel);
		// 	}

		// 	if(action == 'show') { this.offcanvas.show(); }
		// 	if(action == 'hide') { this.offcanvas.hide(); }
		// 	if(action == 'toggle') { this.offcanvas.toggle(); }

		// 	return this.offcanvas;
        // },

        // createEntryIntent() {
        //     this.current.entry = null;
        //     this.current.fields = this.current.form?.content?.fields;

		// 	let panel = document.getElementById('form-panel');
		// 	let offcanvas = new window.bootstrap.Offcanvas(panel);
		// 		offcanvas.show();

		// 	this.panel('show')
        // },

		editApp() {
			let path = this.$root.links.APPS + '/edit/' + this.current?.app?.name;
			this.$router.push({ path: path });
		},

		shareApp() {
			this.$root.notify('TODO: Share Application ('+ this.current?.app?.title +')');
		},

		openWorkbook(data) {
			console.log(data);
			this.current.workbook = data;
            let name = data.name;
			let path = this.$root.links.APPS + '/run/' + this.current.app.name + '/' + name;
			this.$root.$router.push( {path: path} );
		},
		



        // async createEntry(data) {

		// 	let title = data?.title || data?.name;

		// 	if(!title) {
		// 		alert('ERROR: missing title for this entry');
		// 		return;
		// 	}

		// 	let request = {
		// 		post_title: title, 
		// 		post_excerpt: data.excerpt || '', 
		// 		post_content: data.content, 
		// 		meta_input: {
		// 			...{
		// 				form: this.current.form.ID,
		// 				workbook: this.current.workbook?.ID
		// 			}
		// 		}
		// 	};

		// 	await this.$store.dispatch('createEntry', request);
		// 	await this.getFormEntries(this.current.form);
		// 	this.panel('hide');
		// 	this.$root.toast(`The entry "${request.post_title}" has been created.`);
        // },


		
        // async updateEntry(data) {

		// 	let title = data.title || data.name;

		// 	let request = {
		// 		ID: data.ID, 
		// 		post_title: title, 
		// 		post_excerpt: data.excerpt || '', 
		// 		post_content: data.content, 
		// 		meta_input: {
		// 			...{
		// 				form: this.current.form.ID,
		// 				workbook: this.current.workbook?.ID
		// 			}
		// 		}
		// 	};
		// 	await this.$store.dispatch('updateEntry', request);
		// 	// await this.setCurrentEntry(data);
		// 	this.panel('hide');
		// 	this.$root.toast(`The entry "${request.post_title}" has been updated.`);

        // },

		/**
		 * @param data
		 * @param data.name
		 * @param data.title
		 * @param data.excerpt
		 * @param data.content
		 */
        // async deleteEntry(data) {

		// 	this.$root.notify({
		// 		title: 'Confirm',
		// 		type: 'confirm',
		// 		message: 'Are you sure you want to delete this entry (' + data.title + ')?',
		// 		callback: async (okay)=> {
		// 			if(okay) {
		// 				await this.$store.dispatch('deleteEntry', data);
		// 				this.$root.toast(`The form "${data.title}" has been deleted.`);
		// 				await this.getFormEntries(this.current.form);
		// 			}
		// 		}
		// 	});
        // },


		

		// formPanelUpdate(data) {
        //     console.log('formPanelUpdate', data);
		// 	if(data?.ID) {
		// 		this.updateEntry(data);
		// 	} else {
		// 		this.createEntry(data);
		// 	}
		// },


		// entryOnOption(option, entry) {
		// 	// console.log('entryOnOption', option, form)
		// 	let action = option?.name;

		// 	switch (action) {
		// 		case 'details':
		// 			this.editEntryIntent(entry);
		// 			break;
		// 		case 'duplicate':
					
		// 			break;
		// 		case 'delete':
		// 			this.deleteEntry(entry);
		// 			break;
			
		// 		default:
		// 			break;
		// 	}
		// },


        // async setCurrentEntry(entry) {
		// 	/**
		// 	 * force formpanel to redraw data
		// 	 */
        //     this.current.entry = null;
        //     this.current.fields = null;

        //     let entryDetails = await this.$store.dispatch('getEntry', entry.name);

        //     this.current.entry = entryDetails;
        //     this.current.fields = this.current.form?.content?.fields;

		// 	this.panel('show')
        //     console.log(this.current.entry);
        // },



        // async getFormEntries(form) {
        //     this.current.form = form;

        //     await this.$store.dispatch('getEntriesByFormId', this.current.form.ID);
		// 	this.setPath();
        // },



		// setPath(replace = false) {
		// 	let path = this.$root.links.RUN + '/' + this.current.workbook?.name + '/' + this.current.form?.name;

		// 	if(replace) {
		// 		this.$router.replace( { path: path } );
		// 	} else {
		// 		this.$router.push( { path: path } );
		// 	}
		// },


        // entryIcon(type) {
        //     type
        //     return 'article';
        // },


		// async deeplink() {

		// 	if(this.forms?.length && this.forms?.length > 0 && this.$route.params?.form) {
		// 		this.forms.forEach( (item) => {
		// 			if(item.name == this.$route.params?.form) {
		// 				this.current.form = item;
		// 			}
		// 		});
		// 		this.setPath(false);

		// 	} else if(this.forms?.length && this.forms?.length > 0 && !this.$route.params?.form) {
		// 		this.current.form = this.forms[0];
		// 		this.setPath(true)
		// 	}


		// 	console.log('mounted', this.current.form);

		// 	/** will not run if current.form is null */
		// 	this.current.form && await this.getFormEntries(this.current.form);
			
		// },

		/**
		 * Others
		 */
		async refresh(forceRefresh = false) {
			this.loading = true;
			forceRefresh
			let name = this.$route.params?.app || null;
			if(name) {
				this.current.app = await this.$store.dispatch('getApp', name);
				this.current.workbooks = await this.$store.dispatch('getWorkbooksInApp', this.current.app.ID);
			}
			this.loading = false;
		}
    }, 

	async mounted() {
		// this.current.form = null;
		// this.$store.commit('SET_APPS', null);

		await this.refresh(true);
		// this.deeplink();

		// this.panel();



        window.addEventListener('resize', () => {
            this.current.content.width = document.body.offsetWidth;
        }, {one: true});


		window.appRun = this;
	}
}
</script>

<style lang="css" scoped>

.workbook-editor-sidebar {
	width: 320px;
	z-index: 10;
}

</style>